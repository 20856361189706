import { Link, useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import weddinggift from "../../images/wedding-gift.png";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";

import "./index.css";

import amoyIcon from "../../images/AMOY.png";
import polygonIcon from "../../images/POLYGON.png";
import { motion } from "framer-motion";

const contracturl = {
  AMOY: "https://amoy.polygonscan.com/address/0x60109649c4a94736b11c09b921da365322df5877",
  POLYGON:
    "https://polygonscan.com/address/0x60109649c4A94736b11c09B921dA365322Df5877",
};

const tokenUrl = {
  AMOY: "https://amoy.polygonscan.com/token/0x60109649c4a94736b11c09b921da365322df5877?a=",
  POLYGON:
    "https://polygonscan.com/token/0x60109649c4A94736b11c09B921dA365322Df5877?a=",
};

const defaultdetails = {
  exists: false,
  id: 0,
  tokennumber: 0,
  name: "",
  description: "",
  mintnetwork: "",
  contract: "",
  owner: 0,
  location: "",
  artifact: "",
  extra: {
    attributes: [],
  },
  timestampfrom: "",
  timestampto: "",
  email: "",
  public_key: "",
  metadata: "",
  txhash: "",
};

const Pindetails = () => {
  // const user = JSON.parse(localStorage.getItem("user"));
  const [details, setdetails] = useState(defaultdetails);
  const { code } = useParams();
  const user = useRef(null);

  useEffect(() => {
    user.current = JSON.parse(localStorage.getItem("user"));
    const initiatepindetail = async () => {
      const data = await fetchpin();
      if (data.txhash) {
        setdetails(data);
      } else {
        fetch(`${process.env.REACT_APP_BASEURL}/collectors/mint?code=${code}`, {
          headers: {
            Authorization: `Bearer ${user.current.sessionToken}`,
          },
        })
          .then((res) => res.json())
          .then(async (data) => {
            if (!data.error) {
              const detail = await fetchpin();
              setdetails(detail);
            }
          });
      }
    };

    const fetchpin = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/collectors/nft/metadata?code=${code}`,
        {
          headers: {
            Authorization: `Bearer ${user.current.sessionToken}`,
          },
        }
      );
      const data = await response.json();
      return data;
    };

    initiatepindetail();
  }, [code]);

  function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return '<a href="' + url + '" target="_blank">Link</a>';
    });
  }

  const icon = {
    AMOY: amoyIcon,
    POLYGON: polygonIcon,
  };

  const formatDatesTime = (date, userLocation) => {
    if (!date) {
      return;
    }
    let options = {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
      timeZoneName: "short",
    };
    let timeZone;

    if (userLocation === "India") {
      timeZone = "Asia/Kolkata";
    } else if (userLocation === "USA") {
      timeZone = "America/New_York";
    }

    // Use here
    const formattedDateTime = new Intl.DateTimeFormat("en-US", {
      ...options,
      timeZone: timeZone,
    }).format(new Date(date));

    return formattedDateTime;
  };

  return (
    <>
      <div className="pins-template">
        <Link to="/gallery" style={{ textDecoration: "none" }}>
          {" "}
          &larr; Back to Souvenirs
        </Link>
        <div>
          <h2>{details.name}</h2>

          <img
            title="Souvenir PiNs"
            src={weddinggift}
            className="digiimg"
            alt=""
          />
        </div>
        <div className="container-fluid">
          <div className="row" align="center">
            <div className="card bg-light mb-3">
              <div className="card-header" align="center">
                <motion.img
                  className="pin-image"
                  src={details.artifact}
                  alt="NFT"
                  initial={{ rotateY: 0 }}
                  animate={{ rotateY: 360 }}
                  transition={{ duration: 3 }}
                />
              </div>
              <div className="card-body" align="center">
                <h5 className="card-title">Issued by: {details.company}</h5>
                <p>{details.description}</p>
                <p>
                  <span className="fw-bold text-uppercase">Share: </span>
                  <FacebookShareButton
                    url={`${process.env.REACT_APP_SHAREURL}/show/${details.uid}`}
                    hashtag={`Checkout my Souvenir for ${details.name}.`}
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <EmailShareButton
                    url={`${process.env.REACT_APP_SHAREURL}/show/${details.uid}`}
                    subject={`Checkout my Souvenir for ${details.name}.`}
                    hashtag="#clifty"
                  >
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                  <TwitterShareButton
                    url={`${process.env.REACT_APP_SHAREURL}/show/${details.uid}`}
                    title={`Checkout my Souvenir for ${details.name}.`}
                    hashtag="#clifty"
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <LinkedinShareButton
                    url={`${process.env.REACT_APP_SHAREURL}/show/${details.uid}`}
                    title={`Checkout my Souvenir for ${details.name}.`}
                  >
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                  <WhatsappShareButton
                    url={`${process.env.REACT_APP_SHAREURL}/show/${details.uid}`}
                    title={`Checkout my Souvenir for ${details.name}.`}
                    hashtag="#clifty"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                </p>
                <h5>Viewed {details.views} times</h5>
              </div>
            </div>
          </div>

          {(details.timestampfrom || details.timestampto) && (
            <>
              <div className="row" align="center">
                <div className="card bg-light mb-3">
                  <div className="card-header" align="center">
                    Event Details
                  </div>

                  <div className="card-body" align="center">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        {details.location &&
                        details.location.toLowerCase() !== "unknown" &&
                        details.location.toLocaleLowerCase() !== "undefined" ? (
                          <p>
                            <b>location: </b>
                            {details.location}
                          </p>
                        ) : null}
                        {/* <span className="fw-bold">Location: </span>
                        <span>{details.location}</span> */}
                      </li>
                    </ul>
                    <ul className="list-group list-group-flush">
                      {details.timestampfrom && (
                        <li key="timestamp-from" className="list-group-item">
                          <span className="fw-bold">Starts: </span>
                          <span>{formatDatesTime(details.timestampfrom)}</span>
                        </li>
                      )}
                      {details.timestampto && (
                        <li key="timestamp-to" className="list-group-item">
                          <span className="fw-bold">Ends: </span>
                          <span>{formatDatesTime(details.timestampto)}</span>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="row" align="center">
            <div className="card bg-light mb-3">
              <div className="card-header" align="center">
                Properties
              </div>
              <div className="card-body" align="center">
                <ul className="list-group list-group-flush">
                  {details.extra.attributes.map((attribute) => {
                    return (
                      <li
                        className="list-group-item"
                        key={attribute["trait_type"]}
                      >
                        <span className="fw-bold text-uppercase">
                          {attribute["trait_type"]}:{" "}
                        </span>

                        <span
                          dangerouslySetInnerHTML={{
                            __html: urlify(attribute["value"]),
                          }}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          <div className="row" align="center">
            <div className="card bg-light">
              <div className="card-header" align="center">
                NFT Details
              </div>
              <div className="card-body" align="center">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <span className="fw-bold text-uppercase">Network: </span>
                    <span>{details.mintnetwork}</span>
                    <img
                      className="image"
                      style={{ float: "right" }}
                      src={icon[details.mintnetwork]}
                      alt="NFT"
                    />
                  </li>
                  <li className="list-group-item">
                    <span className="fw-bold text-uppercase">
                      Token Unique ID:{" "}
                    </span>

                    <span>{details.uid}</span>
                  </li>
                  <li className="list-group-item">
                    <p>
                      <span className="fw-bold text-uppercase">Contract: </span>

                      <Link
                        to={contracturl[details.mintnetwork]}
                        target="_blank"
                      >
                        Explorer
                      </Link>
                    </p>
                  </li>
                  <li className="list-group-item">
                    <span className="fw-bold text-uppercase">Token: </span>

                    <span>{details.tokennumber}</span>
                  </li>
                  <li className="list-group-item">
                    <span className="fw-bold text-uppercase">Minted: </span>

                    <Link
                      to={tokenUrl[details.mintnetwork] + details.tokennumber}
                      target="_blank"
                    >
                      {details.txhash ? "Yes" : "No"}
                    </Link>
                  </li>
                  <li className="list-group-item">
                    <span className="fw-bold text-uppercase">QR Code: </span>

                    <span>{details.qrid}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </>
  );
};

export default Pindetails;
