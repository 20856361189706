import { Link, useNavigate, useParams } from "react-router-dom";
import "./index.css";
import { useContext, useEffect, useState } from "react";
import Custominput from "../customcomponent/custominput";
import AlertPopup from "../Alert/alert";
import { Alertcontext } from "../../AppContext";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";

const contracturl = {
  AMOY: "https://amoy.polygonscan.com/address/0x60109649c4a94736b11c09b921da365322df5877",
  POLYGON:
    "https://polygonscan.com/address/0x60109649c4A94736b11c09B921dA365322Df5877",
};

const defaultdetails = {
  exists: false,
  id: 0,
  tokennumber: 0,
  name: "",
  description: "",
  mintnetwork: "",
  contract: "",
  owner: 0,
  location: "",
  artifact: "",
  extra: {
    attributes: [],
  },
  timestampfrom: "",
  timestampto: "",
  email: "",
  public_key: "",
  metadata: "",
  txhash: "",
};

const CollectSouvenir = () => {
  const [email, setemail] = useState();
  const [error, setError] = useState();
  const [details, setdetails] = useState(defaultdetails);
  const [newcollector, setnewcollector] = useState(false);
  const { code } = useParams();
  const navigate = useNavigate();
  const alertContext = useContext(Alertcontext);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASEURL}/pin/metadata?code=${code}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setError("No pin associated with this id");
          setTimeout(() => {
            navigate("/");
          }, 3000);
        } else setdetails(data);
      });
  }, [code, navigate]);

  // Function to validate email
  const ValidateEmail = (email) => {
    // eslint-disable-next-line no-useless-escape
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      return true;
    }
    return false;
  };

  function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return '<a href="' + url + '" target="_blank">Link</a>';
    });
  }

  const acceptpins = () => {
    if (!email) {
      setError("Email is required");
    } else if (!ValidateEmail(email)) {
      setError("Invalid email address. Please enter a valid email address.");
    } else {
      fetch(
        `${
          process.env.REACT_APP_BASEURL
        }/collectors/assignpinnft?code=${code}&email=${encodeURIComponent(
          email
        )}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.assigned) {
            alertContext.setAlert({
              show: true,
              variant: "primary",
              message:
                "You have successfully accepted the Souvenir. Please sign in to see it in your account.",
            });

            navigate("/signin");
          } else {
            throw new Error(data.error);
          }
        })
        .catch((err) => {
          if (
            err.message ===
            "You have a new digital collectible reserved. Please sign up for a new Clifty account and see it!"
          )
            setnewcollector(true);
          setError(err.message);
        });
    }
  };

  const handleErrorpopup = () => {
    setError(null);
  };

  const formatDatesTime = (date, userLocation) => {
    if (!date) {
      return;
    }
    let options = {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
      timeZoneName: "short",
    };
    let timeZone;

    if (userLocation === "India") {
      timeZone = "Asia/Kolkata";
    } else if (userLocation === "USA") {
      timeZone = "America/New_York";
    }

    // Use here
    const formattedDateTime = new Intl.DateTimeFormat("en-US", {
      ...options,
      timeZone: timeZone,
    }).format(new Date(date));

    return formattedDateTime;
  };

  return (
    <div className="container collectpinstemplate">
      <CustomAccordion
        title={details.orgname}
        className="title container-70"
        style={{ textAlign: "center" }}
      >
        <>
          <h1>{details.name}</h1>

          <p>
            <img
              // className="pin-image"
              src={`https://asset.clifty.io/${details.artifact.replace(
                "ipfs://",
                "ipfs/"
              )}?pinataGatewayToken=Jj_K5bQqbTXNlJhh6FG9tf31rZP5XPWSGRGEf01BL4S9Ck8EZKABX6nZ-PwyNHf9`}
              alt="nft"
              width="100%"
            />
          </p>
        </>
      </CustomAccordion>

      <CustomAccordion
        title="Accept Digital Souvenir PiNs"
        className="acceptpin container-70"
      >
        {!newcollector && error && (
          <AlertPopup
            Message={error}
            variant="primary"
            setShow={handleErrorpopup}
          />
        )}
        {newcollector && error && (
          <AlertPopup
            Message={error}
            variant="primary"
            setShow={handleErrorpopup}
          >
            <Link to="/signup">Signup</Link>{" "}
          </AlertPopup>
        )}
        <Custominput
          label="Email Address"
          id="email"
          name="email"
          value={email}
          setValue={setemail}
        />
        <button
          className=" mb-3 text-uppercase fw-bold w-100 py-2 btn btn-primary"
          style={{
            backgroundColor: "#f47c04",
            color: "white",
          }}
          disabled={details.active ? false : true}
          onClick={acceptpins}
        >
          {details.active === 0 ? "Unavailable" : "Collect"}
        </button>
      </CustomAccordion>

      <CustomAccordion title="Description" className="description container-70">
        <>
          <p className="">{details.description}</p>
        </>
      </CustomAccordion>

      {(details.timestampfrom || details.timestampto) && (
        <CustomAccordion
          title="Event Details"
          className="eventdate container-70"
        >
          <>
            {details.location &&
            details.location.toLowerCase() !== "unknown" &&
            details.location.toLocaleLowerCase() !== "undefined" ? (
              <p>
                <b>location:</b>
                {details.location}
              </p>
            ) : null}
            {details.timestampfrom && (
              <p>
                <b>Starts:</b> {formatDatesTime(details.timestampfrom)}
              </p>
            )}
            {details.timestampto && (
              <p>
                <b>Ends:</b> {formatDatesTime(details.timestampto)}
              </p>
            )}
          </>
        </CustomAccordion>
      )}

      <CustomAccordion
        title="Additional Information"
        className="properties container-70"
      >
        <ul className="list-group list-group-flush">
          {details.extra.attributes.map((attribute) => {
            return (
              <li key={attribute["trait_type"]} className="list-group-item">
                <span className="fw-bold text-uppercase">
                  {attribute["trait_type"]}:{" "}
                </span>

                <span
                  dangerouslySetInnerHTML={{
                    __html: urlify(attribute["value"]),
                  }}
                />
              </li>
            );
          })}
        </ul>
      </CustomAccordion>
      <CustomAccordion
        title="NFT/Collectible Details"
        className="nft container-70"
      >
        <ul className="list-group list-group-flush">
          <li id="idItem" className="list-group-item">
            <span className="fw-bold text-uppercase">ID: </span>

            <span>{code}</span>
          </li>
          <li id="networkItem" className="list-group-item">
            <span className="fw-bold text-uppercase">Network: </span>

            <span>{details.network}</span>
          </li>
          <li id="typeItem" className="list-group-item">
            <span className="fw-bold text-uppercase">Type: </span>

            <span>ERC-721</span>
          </li>
          <li id="contractItem" className="list-group-item">
            <span className="fw-bold text-uppercase">Contract: </span>

            <Link to={contracturl[details.network]} target="_blank">
              Details
            </Link>
          </li>
        </ul>
      </CustomAccordion>

      <br />
      <br />
    </div>
  );
};

export default CollectSouvenir;

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log("totally custom!")
  );

  return (
    <button type="button" className="btn" onClick={decoratedOnClick}>
      {children}
    </button>
  );
}

function CustomAccordion({ title, children, key, ...otherprops }) {
  return (
    <Accordion defaultActiveKey={key} {...otherprops}>
      <Card>
        <Card.Header
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p>{title}</p>
          <CustomToggle eventKey={key}>+</CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={key}>
          <Card.Body>{children}</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}
