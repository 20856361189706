import { Link, useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import awardIcon from "../../images/award.png";
import "./index.css";
import amoyIcon from "../../images/AMOY.png";
import polygonIcon from "../../images/POLYGON.png";
import { motion } from "framer-motion";

const contracturl = {
  AMOY: "https://amoy.polygonscan.com/address/0x60109649c4a94736b11c09b921da365322df5877",
  POLYGON:
    "https://polygonscan.com/address/0x60109649c4A94736b11c09B921dA365322Df5877",
};

const tokenUrl = {
  AMOY: "https://amoy.polygonscan.com/token/0x60109649c4a94736b11c09b921da365322df5877?a=",
  POLYGON:
    "https://polygonscan.com/token/0x60109649c4A94736b11c09B921dA365322Df5877?a=",
};

const defaultdetails = {};

const AwardDetails = () => {
  const [details, setdetails] = useState(defaultdetails);
  const { code } = useParams();
  const user = useRef(null);
  const idPassed = useParams()["id"];

  useEffect(() => {
    user.current = JSON.parse(localStorage.getItem("user"));
    const initiatepindetail = async () => {
      const data = await fetchpin();
      setdetails(data);
      if (data.txhash) {
      } else {
        // Mint the NFT
        const code = data.token.uid;
        fetch(`${process.env.REACT_APP_BASEURL}/collectors/mint?code=${code}`, {
          headers: {
            Authorization: `Bearer ${user.current.sessionToken}`,
          },
        })
          .then((res) => res.json())
          .then(async (data) => {
            if (!data.error) {
              const detail = await fetchpin();
              setdetails(detail);
            }
          });
      }
    };

    const fetchpin = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/collectors/awards/${idPassed}`,
        {
          headers: {
            Authorization: `Bearer ${user.current.sessionToken}`,
          },
        }
      );
      const data = await response.json();
      return data;
    };

    // Fetch and mint if needed
    initiatepindetail();
  }, [code, idPassed]);

  function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return '<a href="' + url + '" target="_blank">Link</a>';
    });
  }

  const icon = {
    AMOY: amoyIcon,
    POLYGON: polygonIcon,
  };

  const formatDatesTime = (date, userLocation) => {
    if (!date) {
      return;
    }
    let options = {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
      timeZoneName: "short",
    };
    let timeZone;

    if (userLocation === "India") {
      timeZone = "Asia/Kolkata";
    } else if (userLocation === "USA") {
      timeZone = "America/New_York";
    }

    // Use here
    const formattedDateTime = new Intl.DateTimeFormat("en-US", {
      ...options,
      timeZone: timeZone,
    }).format(new Date(date));

    return formattedDateTime;
  };

  return (
    <>
      <div className="pins-template">
        <Link to="/awards" style={{ textDecoration: "none" }}>
          {" "}
          &larr; Back to Awards
        </Link>
        <div>
          <h2>{details && details.name}</h2>

          <img title="Award PiNs" src={awardIcon} className="digiimg" alt="" />
        </div>
        <div className="container-fluid">
          <div className="row" align="center">
            <div className="card bg-light mb-3">
              <div className="card-header" align="center">
                <motion.img
                  className="pin-image"
                  src={details.awardimage}
                  alt="NFT"
                  initial={{ rotateY: 0 }}
                  animate={{ rotateY: 360 }}
                  transition={{ duration: 3 }}
                />
              </div>
              <div className="card-body" align="center">
                <h5 className="card-title">
                  Issued by: {details.souvenir && details.org.name}
                </h5>
                <p>
                  {details.souvenir && decodeURIComponent(details.description)}
                </p>
              </div>
            </div>
          </div>

          {details.souvenir &&
            (details.event.timestampfrom || details.event.timestampto) && (
              <>
                <div className="row" align="center">
                  <div className="card bg-light mb-3">
                    <div className="card-header" align="center">
                      Event Details
                    </div>
                    <div className="card-body" align="center">
                      <ul className="list-group list-group-flush">
                        {details.location &&
                        details.location.toLowerCase() !== "unknown" &&
                        details.location.toLocaleLowerCase() !== "undefined" ? (
                          <p>
                            <b>location:</b>
                            {details.location}
                          </p>
                        ) : null}
                      </ul>
                      {/* </div> */}
                      {/* <div className="card-body" align="center"> */}
                      <ul className="list-group list-group-flush">
                        {details.event.timestampfrom && (
                          <li className="list-group-item">
                            <span className="fw-bold">Starts: </span>
                            <span>
                              {formatDatesTime(details.event.timestampfrom)}
                            </span>
                          </li>
                        )}
                        {details.event.timestampto && (
                          <li className="list-group-item">
                            <span className="fw-bold">Ends: </span>
                            <span>
                              {formatDatesTime(details.event.timestampto)}
                            </span>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            )}

          <div className="row" align="center">
            <div className="card bg-light mb-3">
              <div className="card-header" align="center">
                Event Information
              </div>
              <div className="card-body" align="center">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <b>Name:</b> {details.event && details.event.name}
                  </li>
                  <li className="list-group-item">
                    <img
                      title="Souvenir PiNs"
                      src={details.souvenir && details.souvenir.artifact}
                      alt=""
                      style={{ width: "25%" }}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row" align="center">
            <div className="card bg-light mb-3">
              <div className="card-header" align="center">
                Properties
              </div>
              <div className="card-body" align="center">
                <ul className="list-group list-group-flush">
                  {details.souvenir &&
                    details.souvenir.extra.attributes.map((attribute) => {
                      return (
                        <li className="list-group-item">
                          <span className="fw-bold text-uppercase">
                            {attribute["trait_type"]}:{" "}
                          </span>

                          <span
                            dangerouslySetInnerHTML={{
                              __html: urlify(
                                decodeURIComponent(attribute["value"])
                              ),
                            }}
                          />
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>

          <div className="row" align="center">
            <div className="card bg-light">
              <div className="card-header" align="center">
                NFT Details
              </div>
              <div className="card-body" align="center">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <span className="fw-bold text-uppercase">Network: </span>
                    <span>{details.event && details.event.network}</span>
                    <img
                      className="image"
                      style={{ float: "right" }}
                      src={details.event && icon[details.event.network]}
                      alt="NFT"
                    />
                  </li>
                  <li className="list-group-item">
                    <span className="fw-bold text-uppercase">
                      Token Unique ID:
                    </span>

                    <span>{details.event && details.token.uid}</span>
                  </li>
                  <li className="list-group-item">
                    <p>
                      <span className="fw-bold text-uppercase">Contract: </span>

                      <Link
                        to={details.event && contracturl[details.event.network]}
                        target="_blank"
                      >
                        Explorer
                      </Link>
                    </p>
                  </li>
                  <li className="list-group-item">
                    <span className="fw-bold text-uppercase">Token: </span>

                    <span>{details.token && details.token.tokennumber}</span>
                  </li>
                  <li className="list-group-item">
                    <span className="fw-bold text-uppercase">Minted: </span>

                    <Link
                      to={
                        details.token &&
                        tokenUrl[details.event.network] +
                          details.token.tokennumber
                      }
                      target="_blank"
                    >
                      {details.token && details.token.txhash ? "Yes" : "No"}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </>
  );
};

export default AwardDetails;
