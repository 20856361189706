import { useState, useEffect } from "react";
import "./ValidatePassword.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ValidPassword = ({
  label,
  name,
  id,
  datatestid,
  validcheck,
  value,
  setvalue,
  comparewith,
}) => {
  const [checklist, setChecklist] = useState({});

  const [showValidation, setShowValidation] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // Toogle the password visibility
  const tooglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const validatePassword = (type) => {
    switch (type) {
      case "hasUppercase":
        return /[A-Z]/.test(value);
      case "hasLowercase":
        return /[a-z]/.test(value);
      case "hasNumber":
        return /[0-9]/.test(value);

      case "hasSpecialChar":
        return /[!@#$%^&*(),.?":{}|<>]/.test(value);
      case "hasMinlength":
        return value.length >= 8;
      case "passwordMatch":
        return value === comparewith;
      default:
        return false;
    }
  };

  useEffect(() => {
    const newchecklist = { ...validcheck };
    Object.keys(validcheck).map((check) => {
      newchecklist[check] = { ...newchecklist[check], valid: false };
    });
    setChecklist(newchecklist);
    // console.log(newchecklist);
  }, []);

  useEffect(() => {
    // console.log(value);
    // console.log(checklist);
    const newchecklist = { ...checklist };
    const newchecklistkeys = Object.keys(newchecklist);
    if (newchecklistkeys.length > 0) {
      newchecklistkeys.map((check) => {
        newchecklist[check] = {
          ...newchecklist[check],
          valid: validatePassword(check),
        };
      });
      // console.log(newchecklist);
      setChecklist(newchecklist);
    }
  }, [value]);

  useEffect(() => {
    // console.log(checklist);
  }, [checklist]);
  const handlePasswordChange = (e) => {
    // const inputPassword = e.target.value;
    setvalue(e.target.value);

    // Check if confirm value doesn't matches the updated value
    // setPasswordMatches(inputPassword === confirmPassword);
  };

  return (
    <div className="form-floating mb-3 position-relative">
      <input
        autoComplete="off"
        id={id}
        name={name}
        value={value}
        type={showPassword ? "text" : "password"}
        className="form-control"
        onChange={handlePasswordChange}
        onFocus={() => setShowValidation(true)}
        onBlur={() => setShowValidation(false)}
        max={200}
        maxLength={200}
        required
      />
      <label htmlFor={id}>{label}</label>
      <i
        type="button"
        onClick={tooglePasswordVisibility}
        className="password-icon"
      >
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </i>

      {showValidation && (
        <ul className="password-rule">
          {Object.keys(checklist).map((check) => {
            return (
              <li
                style={{
                  color: checklist[check].valid ? "green" : "red",
                }}
              >
                {checklist[check].valid ? "✔️" : "❌"}{" "}
                {check === "passwordMatch"
                  ? checklist[check].valid
                    ? checklist[check].message.success
                    : checklist[check].message.failure
                  : checklist[check].message}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default ValidPassword;
